import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PortfolioLayout from '../components/portfolioLayout';

const Portfolio = () => {
    const data = useStaticQuery(graphql`
    query {      
      primary1: file(relativePath: { eq: "chimney_before.jpg" }) {
        childImageSharp {
          fluid (maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      primary2: file(relativePath: { eq: "chimney_after.jpg" }) {
        childImageSharp {
          fluid (maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      files: allFile(
        filter: { sourceInstanceName: { eq: "portfolio" }, relativeDirectory: { eq: "roofing" } },
        sort: { fields: [ name ] }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fixed (width: 400){
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  `);
  
  const beforeAfter = [
      {
        'before': data.primary1,
        'after': data.primary2
      }
    ];

  return (
    <PortfolioLayout 
      title='Roofing'
      path='portfolio-roofing'
      data={data.files.edges}
      link='/roofing-contractor' 
      beforeAfter={beforeAfter}
      />
)};

export default Portfolio;
